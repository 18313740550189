import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/travis/build/reach/reach-ui/website/src/components/mdx-layout.js";
import SEO from "../components/SEO";
import { TOC, TOCList, TOCLink } from "../components/TOC";
import { AsPropWarning } from "../components/AsPropWarning";
import { Pipe } from "../components/Pipe";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO title="Combobox" description="Accessible combobox (autocomplete or autosuggest) component for React" mdxType="SEO" />
    <h1 {...{
      "id": "combobox"
    }}>{`Combobox`}</h1>
    <TOC mdxType="TOC">
  <TOCList mdxType="TOCList">
    <TOCLink href="#combobox-1" mdxType="TOCLink">Combobox</TOCLink>
    <TOCLink href="#comboboxinput" mdxType="TOCLink">ComboboxInput</TOCLink>
    <TOCLink href="#comboboxpopover" mdxType="TOCLink">ComboboxPopover</TOCLink>
    <TOCLink href="#comboboxlist" mdxType="TOCLink">ComboboxList</TOCLink>
    <TOCLink href="#comboboxoption" mdxType="TOCLink">ComboboxOption</TOCLink>
    <TOCLink href="#comboboxoptiontext" mdxType="TOCLink">ComboboxOptionText</TOCLink>
    <TOCLink href="#usecomboboxcontext" mdxType="TOCLink">useComboboxContext</TOCLink>
    <TOCLink href="#usecomboboxoptioncontext" mdxType="TOCLink">useComboboxOptionContext</TOCLink>
  </TOCList>
    </TOC>
    <ul>
      <li parentName="ul">{`Source: `}<a parentName="li" {...{
          "href": "https://github.com/reach/reach-ui/tree/main/packages/combobox"
        }}>{`https://github.com/reach/reach-ui/tree/main/packages/combobox`}</a></li>
      <li parentName="ul">{`WAI-ARIA: `}<a parentName="li" {...{
          "href": "https://www.w3.org/TR/wai-aria-practices-1.2/#combobox"
        }}>{`https://www.w3.org/TR/wai-aria-practices-1.2/#combobox`}</a></li>
    </ul>
    <p>{`Accessible combobox (autocomplete or autosuggest) component for React.`}</p>
    <p>{`A combobox is the combination of an `}<inlineCode parentName="p">{`<input type="text"/>`}</inlineCode>{` and a list. The list is designed to help the user arrive at a value, but the value does not necessarily have to come from that list. Don't think of it like a `}<inlineCode parentName="p">{`<select/>`}</inlineCode>{`, but more of an `}<inlineCode parentName="p">{`<input type="text"/>`}</inlineCode>{` with some suggestions. You can, however, validate that the value comes from the list, that's up to your app.`}</p>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`From the command line in your project directory, run `}<inlineCode parentName="p">{`npm install @reach/combobox`}</inlineCode>{` or `}<inlineCode parentName="p">{`yarn add @reach/combobox`}</inlineCode>{`. Then import the components and styles that you need:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`npm install @reach/combobox
# or
yarn add @reach/combobox
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`import {
  Combobox,
  ComboboxInput,
  ComboboxPopover,
  ComboboxList,
  ComboboxOption,
  ComboboxOptionText,
} from "@reach/combobox";
import "@reach/combobox/styles.css";
`}</code></pre>
    <h2 {...{
      "id": "accessibility"
    }}>{`Accessibility`}</h2>
    <p>{`Reach UI aims to handle most ARIA and accessibility concerns so that developers don't have to worry about it. Labeling is often the one thing Reach can't do for you by default since there are many ways to accomplish it, and some of those methods require app-level context.`}</p>
    <p>{`However, we still aim to make accessibility as easy as possible. Labels for the compound `}<inlineCode parentName="p">{`Combobox`}</inlineCode>{` component can go on the parent and we will forward the label to the correct nested component where it belongs.`}</p>
    <p>{`For instance, instead of adding `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` to `}<inlineCode parentName="p">{`<ComboboxInput>`}</inlineCode>{`, we can add it to `}<inlineCode parentName="p">{`<Combobox>`}</inlineCode>{`. The same goes for `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Combobox aria-label="choose a fruit">
  <ComboboxInput />
  <ComboboxPopover>
    <ComboboxList>
      <ComboboxOption value="Apple" />
      <ComboboxOption value="Banana" />
    </ComboboxList>
  </ComboboxPopover>
</Combobox>
`}</code></pre>
    <p>{`One benefit reaped from this pattern is that it alleviates the need for developers to think about where the `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` or `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{` attributes belong in the component tree. Another benefit is that if the ARIA spec changes in the future (as it did from 1.1 to 1.2 for `}<inlineCode parentName="p">{`Combobox`}</inlineCode>{`), Reach doesn't introduce a breaking API change to make accessibility improvements.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`NOTE:`}</strong>{` You can still pass either `}<inlineCode parentName="p">{`aria-label`}</inlineCode>{` or `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{` directly to `}<inlineCode parentName="p">{`ComboboxInput`}</inlineCode>{` if you'd prefer and those values will override either respective prop passed into `}<inlineCode parentName="p">{`Combobox`}</inlineCode>{`, though we discourage this. It's helpful for the component's context to keep a reference to its label. We may remove this option in a future release.`}</p>
    </blockquote>
    <h2 {...{
      "id": "examples"
    }}>{`Examples`}</h2>
    <p>{`To get you started, let's take a look at a few examples that grow from simple to complex, after the examples you can see the API for each component.`}</p>
    <h3 {...{
      "id": "basic-fixed-list-combobox"
    }}>{`Basic, Fixed List Combobox`}</h3>
    <p>{`Like a `}<inlineCode parentName="p">{`<table><tr><td/></tr></table>`}</inlineCode>{`, a full combobox is made up of multiple components. This example demonstrates all of the pieces you need in the simplest form possible.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <div>
      <h4 id="demo">Basic, Fixed List Combobox</h4>
      <Combobox aria-labelledby="demo">
        <ComboboxInput />
        <ComboboxPopover>
          <ComboboxList>
            <ComboboxOption value="Apple" />
            <ComboboxOption value="Banana" />
            <ComboboxOption value="Orange" />
            <ComboboxOption value="Pineapple" />
            <ComboboxOption value="Kiwi" />
          </ComboboxList>
        </ComboboxPopover>
      </Combobox>
    </div>
  );
}
`}</code></pre>
    <h3 {...{
      "id": "custom-rendering-in-comboboxoption"
    }}>{`Custom Rendering in ComboboxOption`}</h3>
    <p>{`Sometimes your items need to be more than just text, in these cases you can pass children to `}<inlineCode parentName="p">{`ComboboxOption`}</inlineCode>{`, and then render a `}<inlineCode parentName="p">{`<ComboboxOptionText/>`}</inlineCode>{` to keep the built-in text highlighting. Only the `}<inlineCode parentName="p">{`value`}</inlineCode>{` is used to match, not the children.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
function Example() {
  return (
    <Combobox aria-label="custom option demo">
      <ComboboxInput
        placeholder="Custom Option Rendering"
        style={{ width: 300 }}
      />
      <ComboboxPopover>
        <ComboboxList>
          <ComboboxOption value="Apple">
            🍎 <ComboboxOptionText />
          </ComboboxOption>
          <ComboboxOption value="Banana">
            🍌 <ComboboxOptionText />
          </ComboboxOption>
          <ComboboxOption value="Orange">
            🍊 <ComboboxOptionText />
          </ComboboxOption>
          <ComboboxOption value="Pineapple">
            🍍 <ComboboxOptionText />
          </ComboboxOption>
          <ComboboxOption value="Kiwi">
            🥝 <ComboboxOptionText />
          </ComboboxOption>
        </ComboboxList>
      </ComboboxPopover>
    </Combobox>
  );
}
`}</code></pre>
    <h3 {...{
      "id": "clientside-search"
    }}>{`Clientside Search`}</h3>
    <p>{`This demo searches a client-side list of all US Cities. Combobox does not implement any matching on your list (aside from highlighting the matched phrases in an option). Instead, you render an Option for each result you want in the list. So your job is to:`}</p>
    <ul>
      <li parentName="ul">{`Establish the search term state`}</li>
      <li parentName="ul">{`Match the search to your list`}</li>
      <li parentName="ul">{`Render a `}<inlineCode parentName="li">{`ComboboxOption`}</inlineCode>{` for each match`}</li>
    </ul>
    <p>{`There is nothing special about managing state for a combobox, it's like managing state for any other list in your app. As the input changes, you figure out what state you need, then render as many ComboboxOption elements as you want.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
(() => {
  function Example() {
    const [term, setTerm] = React.useState("");
    const results = useCityMatch(term);
    const handleChange = (event) => setTerm(event.target.value);

    return (
      <div>
        <h4>Clientside Search</h4>
        <Combobox aria-label="Cities">
          <ComboboxInput
            className="city-search-input"
            onChange={handleChange}
          />
          {results && (
            <ComboboxPopover className="shadow-popup">
              {results.length > 0 ? (
                <ComboboxList>
                  {results.slice(0, 10).map((result, index) => (
                    <ComboboxOption
                      key={index}
                      value={\`\${result.city}, \${result.state}\`}
                    />
                  ))}
                </ComboboxList>
              ) : (
                <span style={{ display: "block", margin: 8 }}>
                  No results found
                </span>
              )}
            </ComboboxPopover>
          )}
        </Combobox>
      </div>
    );
  }

  function useCityMatch(term) {
    const throttledTerm = useThrottle(term, 100);
    return React.useMemo(
      () =>
        term.trim() === ""
          ? null
          : matchSorter(cities, term, {
              keys: [(item) => \`\${item.city}, \${item.state}\`],
            }),
      [throttledTerm]
    );
  }

  return <Example />;
})();
`}</code></pre>
    <h3 {...{
      "id": "server-side-search"
    }}>{`Server Side Search`}</h3>
    <p>{`This is the same demo as above, except this time we're going to a server to get the match. This is recommended as the previous example had to download 350kb of city text! Again, there is nothing special about a ComboboxList as any other list in React. As the input changes, fetch data, set state, render options.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
(() => {
  function Example() {
    const [searchTerm, setSearchTerm] = React.useState("");
    const cities = useCitySearch(searchTerm);
    const handleSearchTermChange = (event) => {
      setSearchTerm(event.target.value);
    };

    return (
      <Combobox aria-label="Cities">
        <ComboboxInput
          className="city-search-input"
          onChange={handleSearchTermChange}
        />
        {cities && (
          <ComboboxPopover className="shadow-popup">
            {cities.length > 0 ? (
              <ComboboxList>
                {cities.map((city) => {
                  const str = \`\${city.city}, \${city.state}\`;
                  return <ComboboxOption key={str} value={str} />;
                })}
              </ComboboxList>
            ) : (
              <span style={{ display: "block", margin: 8 }}>
                No results found
              </span>
            )}
          </ComboboxPopover>
        )}
      </Combobox>
    );
  }

  function useCitySearch(searchTerm) {
    const [cities, setCities] = React.useState([]);

    React.useEffect(() => {
      if (searchTerm.trim() !== "") {
        let isFresh = true;
        fetchCities(searchTerm).then((cities) => {
          if (isFresh) setCities(cities);
        });
        return () => (isFresh = false);
      }
    }, [searchTerm]);

    return cities;
  }

  const cache = {};
  function fetchCities(value) {
    if (cache[value]) {
      return Promise.resolve(cache[value]);
    }
    return fetch("https://city-search.chaance.vercel.app/api?" + value)
      .then((res) => res.json())
      .then((result) => {
        cache[value] = result;
        return result;
      });
  }

  return <Example />;
})();
`}</code></pre>
    <h3 {...{
      "id": "lots-of-arbitrary-elements"
    }}>{`Lots of arbitrary elements`}</h3>
    <p>{`Sometimes your list is a bit more complicated, like categories of results, and lots of elements besides options inside the popover.`}</p>
    <p>{`You can even have other interactive elements inside the popover, it won't close when the user interacts with them.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
(() => {
  function Example() {
    const [term, setTerm] = React.useState("");
    const results = useCityMatch(term);
    const handleChange = (event) => setTerm(event.target.value);

    return (
      <div>
        <h4>Lots of stuff going on</h4>
        <Combobox>
          <ComboboxInput
            onChange={handleChange}
            style={{ width: 300, margin: 0 }}
          />
          {results && (
            <ComboboxPopover style={{ width: 300 }}>
              {results.length > 0 ? (
                <ComboboxList>
                  <h5 style={heading}>Top 3 results!</h5>
                  {results.slice(0, 3).map((result, index) => (
                    <ComboboxOption
                      key={index}
                      value={\`\${result.city}, \${result.state}\`}
                    />
                  ))}
                  {results.length > 3 && (
                    <React.Fragment>
                      <h5 style={heading}>The Rest</h5>
                      {results.slice(3, 10).map((result, index) => (
                        <ComboboxOption
                          key={index}
                          value={\`\${result.city}, \${result.state}\`}
                        />
                      ))}
                    </React.Fragment>
                  )}
                </ComboboxList>
              ) : (
                <div>
                  <p style={{ padding: 10, textAlign: "center" }}>
                    No results 😞
                  </p>
                </div>
              )}
              <p style={{ textAlign: "center", padding: 10 }}>
                <button>Create a new record</button>
              </p>
            </ComboboxPopover>
          )}
        </Combobox>
      </div>
    );
  }

  function useCityMatch(term) {
    const throttledTerm = useThrottle(term, 100);
    return React.useMemo(
      () =>
        term.trim() === ""
          ? null
          : matchSorter(cities, term, {
              keys: [(item) => \`\${item.city}, \${item.state}\`],
            }),
      [throttledTerm]
    );
  }

  const heading = {
    fontSize: "100%",
    color: "red",
    fontWeight: "bold",
    textTransform: "uppercase",
    margin: 0,
    padding: 5,
  };

  return <Example />;
})();
`}</code></pre>
    <h3 {...{
      "id": "custom-styling"
    }}>{`Custom styling`}</h3>
    <p>{`This demo shows how you can control a lot about the styling. It uses `}<inlineCode parentName="p">{`portal={false}`}</inlineCode>{` on the ComboboxPopover which allows us to create a continuous outline around the entire thing.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// jsx-demo
(() => {
  function Example() {
    let [term, setTerm] = React.useState("");
    let results = useCityMatch(term);
    const handleChange = (event) => setTerm(event.target.value);

    return (
      <Combobox className="pink">
        <ComboboxInput onChange={handleChange} />
        {results && (
          <ComboboxPopover portal={false}>
            <hr />
            {results.length > 0 ? (
              <ComboboxList>
                {results.slice(0, 10).map((result, index) => (
                  <ComboboxOption
                    key={index}
                    value={\`\${result.city}, \${result.state}\`}
                  />
                ))}
              </ComboboxList>
            ) : (
              <p
                style={{
                  margin: 0,
                  color: "#454545",
                  padding: "0.25rem 1rem 0.75rem 1rem",
                  fontStyle: "italic",
                }}
              >
                No results :(
              </p>
            )}
          </ComboboxPopover>
        )}
      </Combobox>
    );
  }

  function useCityMatch(term) {
    let throttledTerm = useThrottle(term, 100);
    return React.useMemo(
      () =>
        term.trim() === ""
          ? null
          : matchSorter(cities, term, {
              keys: [(item) => \`\${item.city}, \${item.state}\`],
            }),
      [throttledTerm]
    );
  }

  return <Example />;
})();
`}</code></pre>
    <h2 {...{
      "id": "component-api"
    }}>{`Component API`}</h2>
    <h3 {...{
      "id": "combobox-1"
    }}>{`Combobox`}</h3>
    <p>{`Parent component that sets up the proper ARIA roles and context for the rest of the components.`}</p>
    <h4 {...{
      "id": "combobox-css-selectors"
    }}>{`Combobox CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-combobox] {
}

/* root element in a specific state  */
/* possible states: "idle" | "suggesting" | "navigating" | "interacting"  */
[data-reach-combobox][data-state="STATE_REF"] {
}
`}</code></pre>
    <h4 {...{
      "id": "combobox-props"
    }}>{`Combobox Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#combobox-as"
            }}><inlineCode parentName="a">{`as`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`Component`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#combobox-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#combobox-openonfocus"
            }}><inlineCode parentName="a">{`openOnFocus`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#combobox-onselect"
            }}><inlineCode parentName="a">{`onSelect`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`func`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "combobox-as"
    }}>{`Combobox as`}</h5>
    <p><inlineCode parentName="p">{`as?: keyof JSX.IntrinsicElements | React.ComponentType`}</inlineCode></p>
    <p>{`A string representing an HTML element or a React component that will tell the `}<inlineCode parentName="p">{`ComboboxOption`}</inlineCode>{` what element to render. Defaults to `}<inlineCode parentName="p">{`div`}</inlineCode>{`.`}</p>
    <AsPropWarning mdxType="AsPropWarning" />
    <h5 {...{
      "id": "combobox-children"
    }}>{`Combobox children`}</h5>
    <p><inlineCode parentName="p">{`children: React.ReactNode | ((props: { id: string | undefined; isExpanded: boolean; navigationValue: string | null; state: string }) => React.ReactNode)`}</inlineCode></p>
    <p>{`Combobox expects to receive `}<inlineCode parentName="p">{`ComboboxInput`}</inlineCode>{` and `}<inlineCode parentName="p">{`ComboboxPopover`}</inlineCode>{` as children. You can also pass a render function to expose data for `}<inlineCode parentName="p">{`Combobox`}</inlineCode>{` to its descendants.`}</p>
    <h5 {...{
      "id": "combobox-openonfocus"
    }}>{`Combobox openOnFocus`}</h5>
    <p><inlineCode parentName="p">{`openOnFocus?: boolean`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Combobox openOnFocus />
`}</code></pre>
    <p>{`Defaults to `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
    <p>{`If true, the popover opens when focus is on the text box.`}</p>
    <h5 {...{
      "id": "combobox-onselect"
    }}>{`Combobox onSelect`}</h5>
    <p><inlineCode parentName="p">{`onSelect?(value: string): void`}</inlineCode></p>
    <p>{`Called with the selection value when the user makes a selection from the list.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<Combobox onSelect={(item) => {}} />
`}</code></pre>
    <h3 {...{
      "id": "comboboxinput"
    }}>{`ComboboxInput`}</h3>
    <p>{`Wraps an `}<inlineCode parentName="p">{`<input/>`}</inlineCode>{` with a couple extra props that work with the combobox.`}</p>
    <h4 {...{
      "id": "comboboxinput-css-selectors"
    }}>{`ComboboxInput CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-combobox-input] {
}

/* input element in a specific state */
/* possible states: "idle" | "suggesting" | "navigating" | "interacting"  */
[data-reach-combobox-input][data-state="STATE_REF"] {
}
`}</code></pre>
    <h4 {...{
      "id": "comboboxinput-props"
    }}>{`ComboboxInput Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#comboboxinput-as"
            }}><inlineCode parentName="a">{`as`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`Component`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#comboboxinput-selectonclick"
            }}>{`selectOnClick`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#comboboxinput-autocomplete"
            }}>{`autocomplete`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "comboboxinput-as"
    }}>{`ComboboxInput as`}</h5>
    <p><inlineCode parentName="p">{`as?: keyof JSX.IntrinsicElements | React.ComponentType`}</inlineCode></p>
    <p>{`A string representing an HTML element or a React component that will tell the `}<inlineCode parentName="p">{`ComboboxInput`}</inlineCode>{` what element to render. Defaults to `}<inlineCode parentName="p">{`input`}</inlineCode>{`.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`NOTE:`}</strong>{` Recreating native `}<inlineCode parentName="p">{`input`}</inlineCode>{` behavior and all of its nuance with a non-semantic element is `}<em parentName="p">{`extremely difficult`}</em>{` and may make the component inaccessible to many users. We do not recommend doing this.`}</p>
    </blockquote>
    <h5 {...{
      "id": "comboboxinput-selectonclick"
    }}>{`ComboboxInput selectOnClick`}</h5>
    <p><inlineCode parentName="p">{`selectOnClick?: boolean`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<ComboboxInput selectOnClick />
`}</code></pre>
    <p>{`Defaults to `}<inlineCode parentName="p">{`false`}</inlineCode>{`.`}</p>
    <p>{`If true, when the user clicks inside the text box the current value will be selected. Use this if the user is likely to delete all the text anyway (like the URL bar in browsers).`}</p>
    <p>{`However, if the user is likely to want to tweak the value, leave this `}<inlineCode parentName="p">{`false`}</inlineCode>{`, like a google search--the user is likely wanting to edit their search, not replace it completely.`}</p>
    <h5 {...{
      "id": "comboboxinput-autocomplete"
    }}>{`ComboboxInput autocomplete`}</h5>
    <p><inlineCode parentName="p">{`autocomplete?: boolean`}</inlineCode></p>
    <p>{`Defaults to `}<inlineCode parentName="p">{`true`}</inlineCode>{`.`}</p>
    <p>{`Determines if the value in the input changes or not as the user navigates with the keyboard. If `}<inlineCode parentName="p">{`true`}</inlineCode>{`, the value changes, if `}<inlineCode parentName="p">{`false`}</inlineCode>{` the value doesn't change.`}</p>
    <p>{`Set this to false when you don't really need the value from the input but want to populate some other state (like the recipient selector in Gmail). But if your input is more like a normal `}<inlineCode parentName="p">{`<input type="text"/>`}</inlineCode>{`, then leave the `}<inlineCode parentName="p">{`true`}</inlineCode>{` default.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<ComboboxInput autocomplete={false} />
`}</code></pre>
    <h3 {...{
      "id": "comboboxpopover"
    }}>{`ComboboxPopover`}</h3>
    <p>{`Contains the popup that renders the list. Because some UI needs to render more than the list in the popup, you need to render one of these around the list. For example, maybe you want to render the number of results suggested.`}</p>
    <h4 {...{
      "id": "comboboxpopover-css-selectors"
    }}>{`ComboboxPopover CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-combobox-popover] {
}

/* popover element in a specific state */
/* possible states: "idle" | "suggesting" | "navigating" | "interacting"  */
[data-reach-combobox-popover][data-state="STATE_REF"] {
}
`}</code></pre>
    <h4 {...{
      "id": "comboboxpopover-props"
    }}>{`ComboboxPopover Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#comboboxpopover-portal"
            }}><inlineCode parentName="a">{`portal`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "comboboxpopover-portal"
    }}>{`ComboboxPopover portal`}</h5>
    <p>{`If you pass `}<inlineCode parentName="p">{`<ComboboxPopover portal={false} />`}</inlineCode>{` the popover will not render inside of a portal, but in the same order as the React tree. This is mostly useful for styling the entire component together, like the pink focus outline in the example earlier in this page.`}</p>
    <p>{`Defaults to `}<inlineCode parentName="p">{`true`}</inlineCode>{`.`}</p>
    <h3 {...{
      "id": "comboboxlist"
    }}>{`ComboboxList`}</h3>
    <p>{`Contains the `}<inlineCode parentName="p">{`ComboboxOption`}</inlineCode>{` elements and sets up the proper aria attributes for the list.`}</p>
    <h4 {...{
      "id": "comboboxlist-css-selectors"
    }}>{`ComboboxList CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-combobox-list] {
}
`}</code></pre>
    <h4 {...{
      "id": "comboboxlist-props"
    }}>{`ComboboxList Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#comboboxlist-as"
            }}><inlineCode parentName="a">{`as`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`Component`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#comboboxlist-persistselection"
            }}><inlineCode parentName="a">{`persistSelection`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`boolean`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "comboboxlist-as"
    }}>{`ComboboxList as`}</h5>
    <p><inlineCode parentName="p">{`as?: keyof JSX.IntrinsicElements | React.ComponentType`}</inlineCode></p>
    <p>{`A string representing an HTML element or a React component that will tell the `}<inlineCode parentName="p">{`ComboboxList`}</inlineCode>{` what element to render. Defaults to `}<inlineCode parentName="p">{`ul`}</inlineCode>{`.`}</p>
    <AsPropWarning mdxType="AsPropWarning" />
    <h5 {...{
      "id": "comboboxlist-persistselection"
    }}>{`ComboboxList persistSelection`}</h5>
    <p><inlineCode parentName="p">{`persistSelection?: boolean`}</inlineCode></p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<ComboboxList persistSelection />
`}</code></pre>
    <p>{`Defaults to `}<inlineCode parentName="p">{`false`}</inlineCode>{`. When `}<inlineCode parentName="p">{`true`}</inlineCode>{` and the list is opened, if an option's value matches the value in the input, it will automatically be highlighted and be the starting point for any keyboard navigation of the list.`}</p>
    <p>{`This allows you to treat a Combobox more like a `}<inlineCode parentName="p">{`<select>`}</inlineCode>{` than an `}<inlineCode parentName="p">{`<input/>`}</inlineCode>{`, but be mindful that the user is still able to put any arbitrary value into the input, so if the only valid values for the input are from the list, your app will need to do that validation on blur or submit of the form.`}</p>
    <h3 {...{
      "id": "comboboxoption"
    }}>{`ComboboxOption`}</h3>
    <p>{`An option that is suggested to the user as they interact with the combobox.`}</p>
    <h4 {...{
      "id": "comboboxoption-css-selectors"
    }}>{`ComboboxOption CSS Selectors`}</h4>
    <p>{`Please see the `}<a parentName="p" {...{
        "href": "/styling"
      }}>{`styling guide`}</a>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-combobox-option] {
}

/* option element when highlighted */
[data-reach-combobox-option][data-highlighted] {
}
`}</code></pre>
    <h4 {...{
      "id": "comboboxoption-props"
    }}>{`ComboboxOption Props`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Prop`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Required`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#comboboxoption-as"
            }}><inlineCode parentName="a">{`as`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode>{` `}<Pipe mdxType="Pipe" />{` `}<inlineCode parentName="td">{`Component`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#comboboxoption-value"
            }}><inlineCode parentName="a">{`value`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`string`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`true`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "#comboboxoption-children"
            }}><inlineCode parentName="a">{`children`}</inlineCode></a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`node`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`false`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "comboboxoption-as"
    }}>{`ComboboxOption as`}</h5>
    <p><inlineCode parentName="p">{`as?: keyof JSX.IntrinsicElements | React.ComponentType`}</inlineCode></p>
    <p>{`A string representing an HTML element or a React component that will tell the `}<inlineCode parentName="p">{`ComboboxOption`}</inlineCode>{` what element to render. Defaults to `}<inlineCode parentName="p">{`li`}</inlineCode>{`.`}</p>
    <AsPropWarning mdxType="AsPropWarning" />
    <h5 {...{
      "id": "comboboxoption-value"
    }}>{`ComboboxOption value`}</h5>
    <p><inlineCode parentName="p">{`value?: string`}</inlineCode></p>
    <p>{`The value to match against when suggesting.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<ComboboxOption value="Salt Lake City, Utah" />
`}</code></pre>
    <h5 {...{
      "id": "comboboxoption-children"
    }}>{`ComboboxOption children`}</h5>
    <p><inlineCode parentName="p">{`children?: React.ReactNode | ((props: { value: string; index: number }) => React.ReactNode)`}</inlineCode></p>
    <p>{`Optional. If omitted, the `}<inlineCode parentName="p">{`value`}</inlineCode>{` will be used as the children like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<ComboboxOption value="Seattle, Tacoma, Washington" />
`}</code></pre>
    <p>{`But if you need to control a bit more, you can put whatever children you want, but make sure to render a `}<inlineCode parentName="p">{`ComboboxOptionText`}</inlineCode>{` as well, so the value is still displayed with the text highlighting on the matched portions.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<ComboboxOption value="Apple" />
🍎 <ComboboxOptionText/>
</ComboboxOption>
`}</code></pre>
    <h3 {...{
      "id": "comboboxoptiontext"
    }}>{`ComboboxOptionText`}</h3>
    <p>{`Renders the value of a `}<inlineCode parentName="p">{`ComboboxOption`}</inlineCode>{` as text but with spans wrapping the matching and non-matching segments of text.`}</p>
    <p>{`So given an option like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<ComboboxOption value="Seattle">
  🌧 <ComboboxOptionText />
</ComboboxOption>
`}</code></pre>
    <p>{`And the user typed `}<inlineCode parentName="p">{`Sea`}</inlineCode>{`, the out would be:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`<span data-user-value>Sea</span><span data-suggested-value>ttle</span>
`}</code></pre>
    <h4 {...{
      "id": "comboboxoptiontext-css-selectors"
    }}>{`ComboboxOptionText CSS Selectors`}</h4>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`[data-reach-combobox-option-text] {
}

/* the matching segments of text */
[data-user-value] {
}

/* the unmatching segments */
[data-suggested-value] {
}
`}</code></pre>
    <h3 {...{
      "id": "usecomboboxcontext"
    }}>{`useComboboxContext`}</h3>
    <p><inlineCode parentName="p">{`function useComboboxContext(): { id: string | undefined; isExpanded: boolean; navigationValue: string | null; state: string }`}</inlineCode></p>
    <p>{`A hook that exposes data for a given `}<inlineCode parentName="p">{`Combobox`}</inlineCode>{` component to its descendants.`}</p>
    <h3 {...{
      "id": "usecomboboxoptioncontext"
    }}>{`useComboboxOptionContext`}</h3>
    <p><inlineCode parentName="p">{`function useComboboxOptionContext(): { value: string; index: number }`}</inlineCode></p>
    <p>{`A hook that exposes data for a given `}<inlineCode parentName="p">{`ComboboxOption`}</inlineCode>{` component to its descendants.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      